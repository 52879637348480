{
  "validations": {
    "required": "Verdien er påkrevd",
    "email": "Må være en gyldig e-post adresse",
    "minLength": "{property} lengde er {min}",
    "exists": "{property} finnes fra før",
    "nonEmptyGuid": "@:validations.required",
    "customer-number-already-in-use": "Kundenummer er allerede i bruk",
    "start-number-must-be-greater-than-last-used": "Startnummer må være større enn siste brukte kundenummer"
  },
  "placeholder": {
    "select": "Velg {property}",
    "type": "Skriv inn {property}"
  },
  "common": {
    "created": "Opprettet",
    "add": "Legg til",
    "save":"Lagre",
    "update": "Oppdater",
    "cancel": "Avbryt",
    "delete": "Slett",
    "close": "Lukk",
    "create": "Opprett",
    "reload": "Last inn på nytt",
    "column-chooser": "Kolonnevelger",
    "reset": "Tilbakestill kolonner",
    "addresses": "Adresser",
    "name": "Navn",
    "description": "Beskrivelse",
    "phone-label": "Telefonnummer",
    "phone-placeholder": "Skriv telefonnummer",
    "loading-failed":"Kunne ikke laste inn kunde, grunn :",
    "current-page-template": "Viser {first} til {last} av totalt {totalRecords} kunder",
    "error": "En feil oppsto",
    "unsaved-changes-header": "Ulagrede endringer",
    "unsaved-changes-text":"Du har endringer som ikke er lagret. Lagre dine endringene eller Forkast for å forlate uten å lagre. Velg Avbryt for a fortsette redigeringen",
    "discard":"Forkast",
    "delete-confirm": "Vil du slette dette elementet?",
    "save-tooltip": "Ingen endringer",
    "validation-error": {
      "summary": "Validering feilet",
      "detail": "Rett alle rød felter og prøv igjen"
    },
    "document-language": {
      "NO": "Norsk",
      "DK": "Dansk",
      "FI": "Finsk",
      "SE": "Svensk",
      "DE": "Tysk",
      "IT": "Italiensk",
      "FR": "Fransk",
      "EN": "Engelsk"
    },
    "address": {
      "address-lines": {
        "label": "Adresselinjer",
        "placeholder": "Skriv inn adresselinjer, skill med enter",
        "error": "Minst en adresselinje er påkrevet"
      },
      "postal-code-city": "Postnr./sted",
      "postal-code": "Postnr.",
      "city": "By",
      "country": "Land"
    },
    "state": {
      "active": "Aktiv",
      "inactive": "Inaktiv",
      "blocked": "Sperret"
    },
    "validation": {
      "email-exist": "E-posten finnes fra før",
      "future-end-date": "Sluttdatoen må angis i fremtiden"
    },
    "error-detail": {
      "unknown": "Ukjent feil",
      "server-error": "Server feil",
      "bad-request": "Feil i forespørsel"
    }
  },
  "customer": {
    "add-customer":"Legg Til Kunde",
    "edit-customer":"Endre Kunde",
    "search-customer":"Søk Kunde",
    "customer-settings":"Kunde Settings",
    "first-name": "Fornavn",
    "last-name": "Etternavn",
    "confirm-delete": "Er du sikker på at du ønsker å slette denne kunden?",
    "created-message": "Ny kunde opprettet: ",
    "created-date":"Registerert",
    "currency": "Valuta",
    "customer-status": "Kundestatus",
    "document-language": "Dokumentspråk",
    "organisation-number": "Organisasjonsnummer",
    "company-name": "Firmanavn",
    "email": "E-post",
    "conditions": "Betingelser",
    "freight": "Frakt",
    "credit-limit": "Kredittgrense",
    "payment-term": "Betalingsbetingelse",
    "agreement-price": "Pris",
    "start-date": "Startdato",
    "end-date": "Sluttdato",
    "freight-method": "Standard fraktmåte",
    "load-error": "Kunne ikke laste inn kunde",
    "reload-customer": "Laster inn kunde på nytt, vennligst vent...",
    "customer-type": "Kundetype",
    "terms-and-agreements": "Betingelser og avtaler",
    "business-customer": "Bedriftskunde",
    "customer-number": {
      "label": "Kundenummer",
      "placeholder": "Automatisk tildeling, klikk for å overstyre"
    },
    "import": {
      "label": "Importer kunder",
      "browse": "Velg fil",
      "number-of-columns-error": "Antall kolonner samsvarer ikke med {customerType} kolonner",
        "invalid-columns-error": "Ugyldig kolonne i csv-fil: {templateColumn} Forventet: {expectedColumn}"
    },
    "type":{
      "b2b": "Bedriftskunde",
      "b2c":"Privatkunde"
    },
    "toast": {
      "import": {
        "success": "Kunder ble importert",
        "error": "Importering av kunder feilet!"
      },
      "edit": {
        "success-summary": "Kunden ble oppdatert!" ,
        "success-detail": "Omdirigerer nå til kundeliste",
        "error-summary": "Oppdatering feilet",
        "error-detail": "Kunne ikke oppdatere kunde"
      },
      "delete": {
        "success-summary": "Kunden er slettet",
        "error-summary": "Kunne ikke slette kunde"
      },
      "no-custom-selected": "Ingen kunde er valgt. Velg en kunde og forsøk igjen.",
      "open-customer-failed": "Kunne ikke åpne kunde"
    },
    "contact-list": {
      "label": "Kontaktpersoner",
      "first-name": "Fornavn",
      "last-name": "Etternavn",
      "email": "E-post",
      "phone-number": "Telefonnummer",
      "invoice-recipient": "Faktura",
      "invoice-tooltip": "Velg hvis du vil at en kontakt skal ha faktura og kreditnota på epost istedenfor",
      "not-found": "Ingen kontakter funnet",
      "delete-confirm": "Er du sikker på at du ønsker å slette denne kontakten?"
    },
    "freight-agreements": {
      "freight-method": "Fraktmåte",
      "label": "Fraktmetoder",
      "tooltip": "Sett en eller flere fraktavtaler for denne kunden",
      "fixed-freight": "Fast frakt",
      "no-agreements-found": "Ingen fraktavtaler funnet for denne kunden",
      "delete-confirm": "Er du sikker på at du ønsker å slette denne fraktavtalen?"
    },
    "address": {
      "invoice": "Invoice address",
      "delivery": "Delivery address"
    },
    "customer-group": {
      "name": "Navn",
      "description": "Beskrivelse",
      "customer-groups": "Kundegrupper",
      "not-found": "Ingen kundegrupper funnet",
      "server-error": "Kunne ikke laste inn kundegrupper",
      "label": "Kundegruppe",
      "select": "Velg en kundegruppe...",
      "search": "Søk etter kundegruppe...",
      "loading": "Laster inn kundegrupper...",
      "delete-confirm": "Er du sikker på at du ønsker å slette denne kundegruppen { groupName }?",
      "success-create": {
        "summary": "Kundegruppe ble lagt til!",
        "detail": "Legger nå til kundegruppe for kunden.."
      },
      "success-update": {
        "summary": "Kundegruppen er oppdatert!",
        "detail": "For kundegruppe {name}"
      },
      "success-delete": {
        "summary": "Kundegruppe er slettet!",
        "detail": "For kundegruppe {name}"
      }
    },
    "header": {
      "add-customer": "Legg til kunde",
      "edit-customer": "Oppdater kunde"
    },
    "search-header": {
      "add-customer": "Legg til kunde",
      "customers": "Kunde",
      "reset-filter":"Reset filter"
    },
    "search": {
      "total-hits": "{totalHits} kunder funnet",
      "delete-selected": "Slett valgte",
      "header-registered-date":"Registerert",
      "header-number": "Kundenummer",
      "header-name": "Navn",
      "header-email": "E-post",
      "header-phone": "Telefon",
      "header-delivery-address": "Leveringsadresse",
      "header-delivery-city": "Poststed",
      "header-address": "Fakturaadresse",
      "header-city": "Faktura poststed",
      "header-country":"Land",
      "header-business-customer": "Bedrift",
      "header-contact-name": "Kontaktnavn",
      "header-contact-email": "Kontakt e-post",
      "header-contact-phone": "Kontakt telefon",
      "header-groups": "Kundegrupper",
      "search-error": "Søk feilet",
      "empty-list": "Ingen kunder funnet.",
      "loading-list": "Laster inn kunder, vennligst vent...",
      "filter": "Filter",
      "filter-date-from": "Fra{symbol}",
      "filter-date-to": "Til{symbol}",
      "filter-customer-registered-date": "Registerings dato",
      "filter-customer-type": "Kunde type",
      "filter-customer-group":"Kunde gruppe",
      "show-filter": "Vis filter",
      "hide-filter": "Skjul filter",
      "clear-filter": "Tøm filter"
    }
  }
}
