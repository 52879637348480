<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <Header
    :editMode="false"
    :hasUpdates="hasUpdates"
    :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
    @onCommit="onCreate"
    @onCancel="onCancel"
    @stayOnPage="stayOnPage"
    @routeToCustomerList="routeToCustomerList"
  />
  <div class="c-add-customer">
    <Message v-for="customer of customersCreated" severity="info" :key="customer.id">
      {{ t("customer.created-message")
      }}<a class="customer-created-link" :href="`/customer/edit/${customer.id}`" :closable="true">
        {{
          customer.customerType === CustomerTypeModel.B2B
            ? customer.businessCustomer.companyName
            : customer.privateCustomer.firstName + " " + customer.privateCustomer.lastName
        }}
      </a>
    </Message>
    <div class="sm:flex">
      <div class="w-full sm:w-3/4 mr-6 mb-8">
        <Card class="h-full">
          <template #content>
            <div class="flex-1 mr-4">
              <div class="grid grid-cols-2 gap-1">
                <div class="mt-3 mb-6">
                  <FirstName
                    v-if="!isBusinessCustomer"
                    v-model:firstName="customer.privateCustomer.firstName"
                    :focusInput="true"
                  />
                  <CompanyName
                    v-if="isBusinessCustomer"
                    v-model:companyName="customer.businessCustomer.companyName"
                    :focusInput="true"
                  />
                </div>
                <div class="mt-3 mb-6">
                  <OrganizationNumber
                    v-if="isBusinessCustomer"
                    v-model:organizationNumber="customer.businessCustomer.organizationNumber"
                  />
                  <LastName v-if="!isBusinessCustomer" v-model:lastName="customer.privateCustomer.lastName" />
                </div>
                <div class="mb-6">
                  <CustomerEmail v-model:emailAddress="customer.email" />
                </div>
                <div class="mb-6">
                  <PhoneInput id="customer-phone" v-model="customer.phoneNumber" />
                </div>
                <div class="mb-2">
                  <CustomerNumber v-model:customerNumber="customer.customerNumber" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="w-full sm:w-1/4 mb-8">
        <Card class="c-card-font h-full">
          <template #content>
            <div class="flex-1">
              <div class="px-2 -mx-4">
                <div class="mt-6 mb-7">
                  <CustomerType v-model:customerType="customer.customerType" />
                </div>

                <div class="mb-6">
                  <CustomerState v-model:customerState="customer.customerState" />
                </div>

                <div class="mb-2">
                  <DocumentLanguage v-model:documentLanguage="customer.documentLanguageIso" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <div class="mb-8">
      <Card class="c-card-font">
        <template #title> {{ t("common.addresses") }} </template>
        <template #content>
          <div class="sm:flex mb-4">
            <div class="w2-full flex-1 xl:border-r-2">
              <Address
                :selectedCountry="customer.deliveryAddress.countryIso"
                :address="customer.deliveryAddress"
                :countryOptions="countryOptions"
                @setAddress="setDeliveryAddress"
                @autoSelectCountry="autoSelectedCountry"
                :addressType="addressTypeDelivery"
                :addressLabel="t('customer.address.delivery')"
              />
            </div>
            <div class="w-full flex-1 pl-3">
              <Address
                :selectedCountry="customer.address.countryIso"
                :address="customer.address"
                :countryOptions="countryOptions"
                @setAddress="setCompanyAddress"
                @autoSelectCountry="autoSelectedCountry"
                :addressType="addressTypeInvoice"
                :addressLabel="t('customer.address.invoice')"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="mb-8">
      <Card class="c-card-font">
        <template #title> {{ t("customer.terms-and-agreements") }} </template>
        <template #content>
          <div class="sm:flex">
            <div class="flex-1 field mx-2 mt-3">
              <DefaultFreightMethod
                v-model:defaultFreightMethod="customer.freight.defaultFreightMethodId"
                :freightMethods="freightMethods"
              />
            </div>
            <div class="flex-1 field mx-2 mt-3">
              <DefaultPaymentTerm v-model:paymentTermId="customer.payment.defaultPaymentTermId" />
            </div>
            <div class="flex-1 field mx-2 mt-3"><CreditLimit v-model:creditLimit="customer.payment.creditLimit" /></div>
            <div class="flex-1 field pl-3 mt-3 xl:border-r-2">
              <Currency v-model:currency="customer.payment.currencyIso" />
            </div>

            <div class="flex-1 field mx-2 mt-3">
              <CustomerGroup v-model:customerGroups="customer.customerGroupIds" />
            </div>
          </div>
          <div class="sm:flex c-card-font">
            <div class="w-4/5 xl:border-r-2">
              <FreightAgreements
                v-model:freightAgreements="customer.freight.freightAgreements"
                :freightMethods="freightMethods"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Card class="c-card-font" v-if="isBusinessCustomer">
      <template #title> {{ t("customer.contact-list.label") }} </template>
      <template #content>
        <div class="w-full">
          <ContactList v-model:contacts="customer.businessCustomer.contacts" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { ShortcutAction, useShortcut } from "@cumulus/shortcut";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import OrganizationNumber from "../components/OrganizationNumber.vue";
import CustomerType from "../components/CustomerType.vue";
import Address from "../components/Address.vue";
import CreditLimit from "../components/CreditLimit.vue";
import DefaultPaymentTerm from "../components/DefaultPaymentTerm.vue";
import CustomerGroup from "../components/customer-group/CustomerGroup.vue";
import DefaultFreightMethod from "../components/DefaultFreightMethod.vue";
import FreightAgreements from "../components/FreightAgreements.vue";
import Currency from "../components/Currency.vue";
import CustomerEmail from "../components/CustomerEmail.vue";
import PhoneInput from "../components/PhoneInput.vue";
import ContactList from "../components/ContactList.vue";
import Header from "../components/Header.vue";
import DocumentLanguage from "../components/DocumentLanguage.vue";
import FirstName from "../components/FirstName.vue";
import LastName from "../components/LastName.vue";
import CompanyName from "../components/CompanyName.vue";
import CustomerNumber from "../components/CustomerNumber.vue";
import CustomerState from "../components/CustomerState.vue";
import { customerAddressFunctions } from "../hooks/CustomerAddressFunction";

import { NewCustomer } from "@/models/customer/NewCustomer";
import { CustomerType as CustomerTypeModel } from "@/models/customer/CustomerType";
import { Country } from "@/models/country/Country";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useCustomer } from "@/api/customer/CustomerService";
import { useCountry } from "@/api/country/CountryService";
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useAuth } from "@cumulus/event-bus";
import { useClient } from "@/api/client/ClientService";
const { createCustomer } = useCustomer();
const { getAllCountries } = useCountry();
const { getAllFreightMethods } = useFreightMethod();

const val = useValidate();
const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();
const customer = ref(new NewCustomer());
const customersCreated = ref<NewCustomer[]>([]);
const addressTypeDelivery = ref("delivery");
const addressTypeInvoice = ref("invoice");
const { setCompanyAddress, setDeliveryAddress } = customerAddressFunctions(customer);

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const initialCustomer = ref<NewCustomer>();
const confirmedDiscard = ref(false);

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});

const routeToCustomerList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/customer/search") === -1) {
    router.push({ name: "customer-search", query: { search: "" } });
  } else {
    router.back();
  }
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onCreate = async () => {
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await createCustomer(customer.value);

  customersCreated.value.push(customer.value);
  customer.value = new NewCustomer();
  val.value.$reset();
  window.scrollTo(0, 0);
};

const isBusinessCustomer = computed<boolean>(() => {
  return customer.value.customerType === CustomerTypeModel.B2B;
});

const countryOptions = ref<Country[]>([]);
const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const freightMethods = ref<FreightMethod[]>([]);
const fetchFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};

const autoSelectedCountry = (countryIso: string) => {
  if (customer.value.deliveryAddress.countryIso === "") {
    customer.value.deliveryAddress.countryIso = countryIso;
    customer.value.address.countryIso = countryIso;
  }
};

const { getAuthHeaders } = useAuth();
const { getClient } = useClient();

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([fetchCountryData(), fetchFreightMethods()]).then(() => {
    initialCustomer.value = cloneDeep(customer.value);
  });

  const client = await getClient((await getAuthHeaders()).clientId);
  customer.value.address.countryIso = client.countryIso;
  customer.value.payment.currencyIso = client.currencyIso;
  customer.value.documentLanguageIso = client.countryIso;
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    routeToCustomerList();
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const hasUpdates = computed(() => {
  return !isEqual(customer.value, initialCustomer.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToCustomerList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

useShortcut(ShortcutAction.save, onCreate);

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});
</script>

<style scoped lang="scss">
.c-add-customer {
  margin: var(--default-content-margin);

  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}

.customer-created-link {
  color: var(--c-blue-50);
  text-decoration: underline;
}

:deep(.p-message .p-message-wrapper) {
  padding: 0.5rem 1.5rem;
}

:deep(.p-message.p-message-info) {
  background: var(--primary-color);
}

.c-purchase-order-lines-card {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.c-card-font {
  font-size: 12.8px;
}

.c-content {
  position: relative;
  transition: all 0.25s;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
</style>
